import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../Imagenes/pmcustom_preview_lav-2.png";

const NavbarL = ({ onNavigate }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [pendingScroll, setPendingScroll] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleMenuClick = (key) => {
    setMenuOpen(false);

    const isHomeSection = ['home', 'servicios', 'corfo'].includes(key);
    const isSomosPMCustomSection = ['SomosPMCustom', 'Contactanos'].includes(key);

    const targetPath = isHomeSection ? '/' : '/SomosPMCustom';

    if (location.pathname !== targetPath) {
      navigate(targetPath); // 🔹 Cambiamos de página primero
      setPendingScroll(key); // 🔹 Guardamos la sección para hacer scroll después
    } else {
      // 🔹 Si ya estamos en la página correcta, ejecutamos el scroll inmediatamente
      onNavigate && onNavigate(key);
    }
  };

  // 🔹 Esperamos que la página cambie antes de hacer scroll a la sección deseada
  useEffect(() => {
    if (pendingScroll) {
      setTimeout(() => {
        onNavigate && onNavigate(pendingScroll);
        setPendingScroll(null); // 🔹 Reseteamos la variable después de ejecutar el scroll
      }, 200); // Ajustamos el tiempo para asegurar que la página haya cargado
    }
  }, [location.pathname, pendingScroll, onNavigate]);

  return (
    <AppBar position="fixed" color="default">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} sm={4} md={3}>
              <img
                src={Logo}
                alt="Logo"
                style={{ width: '80%', height: '80%', marginTop: '10px', marginBottom: '5px' }}
              />
            </Grid>

            {isSmallScreen ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenuToggle}
                  sx={{ marginLeft: 'auto' }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={isMenuOpen}
                  onClose={handleMenuToggle}
                >
                  <List>
                    <ListItem button onClick={() => handleMenuClick('home')}>
                      <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => handleMenuClick('servicios')}>
                      <ListItemText primary="Nuestros Servicios" />
                    </ListItem>
                    <ListItem button onClick={() => handleMenuClick('corfo')}>
                      <ListItemText primary="Corfo" />
                    </ListItem>
                    <ListItem button onClick={() => handleMenuClick('SomosPMCustom')}>
                      <ListItemText primary="Somos PMCustom" />
                    </ListItem>
                    <ListItem button onClick={() => handleMenuClick('Contactanos')}>
                      <ListItemText primary="Contáctanos" />
                    </ListItem>
                  </List>
                </Drawer>
              </>
            ) : (
              <Grid item sm={8} container justifyContent="flex-end">
                <Button onClick={() => handleMenuClick('home')} sx={{ color: "black" }}>
                  Home
                </Button>
                <Button onClick={() => handleMenuClick('servicios')} sx={{ color: "black" }}>
                  Nuestros Servicios
                </Button>
                <Button onClick={() => handleMenuClick('corfo')} sx={{ color: "black" }}>
                  Corfo
                </Button>
                <Button onClick={() => handleMenuClick('SomosPMCustom')} sx={{ color: "black" }}>
                  Somos PMCustom
                </Button>
                <Button onClick={() => handleMenuClick('Contactanos')} sx={{ color: "black" }}>
                  Contáctanos
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavbarL;
