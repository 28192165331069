import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from "react";
import Home from './components/PaginaWeb/Pagina de inicio/Home';
import Page404 from './components/PaginaWeb/PageError';
import SomosPMCustom from './components/PaginaWeb/Pagina de inicio/SomosPMCustom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/SomosPMCustom" element={<SomosPMCustom />} />
        {/* Ruta por defecto 404 */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
