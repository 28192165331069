import React, { useState, useRef,useEffect  } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
    Grid,
    Box,
    Typography,
    Container,
    Button,
    CardMedia,
    Dialog,
    DialogContent
} from "@mui/material";

import LogoPlacaPmCustom from "./../Imagenes/Logo_Placa_PmCustom.jpg";
import Fondo_IOT from "./../Imagenes/Fondo_IOT.jpg";
import ProyectosIot_edited from "./../Imagenes/ProyectosIot_edited.png";
import Fondo_Corfo from "./../Imagenes/Fondo_Corfo.png";
import Imagen_Corfo from "./../Imagenes/Corfo.png";
import ciclopmcustom from "./../Imagenes/ciclopmcustom.png";
import Desarrollodispositivos from "./../Imagenes/Dessarrollodispisitivos.png";
import Dashboarddecontrolweb from "./../Imagenes/dashboarddecontrolweb.png";
import Integracionesweb from "./../Imagenes/Integracionesweb.png";
import Servidores from "./../Imagenes/servidores.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useNavigate, useLocation } from 'react-router-dom';

const Home = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [pendingScroll, setPendingScroll] = useState(null);

    useEffect(() => {
        if (pendingScroll) {
            handleScrollTo(pendingScroll);
            setPendingScroll(null);
        }
    }, [pendingScroll]);

    // -- Estilos de background --
    const containerStyle = {
        background: `linear-gradient(rgba(0, 0, 60, 0.7), rgba(0, 0, 60, 0.7)), url('${LogoPlacaPmCustom}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        minHeight: '30vh',
    };

    const containerStylee = {
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${Fondo_IOT}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '40vh',
    };

    const containerStyleee = {
        background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('${Fondo_Corfo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '10vh',
    };

    // -- Referencias para scroll --
    const homeRef = useRef(null);
    const serviciosRef = useRef(null);
    const corfoRef = useRef(null);

    const handleMenuClick = (key) => {
        const isHomeSection = ['home', 'servicios', 'corfo'].includes(key);
        const targetPath = isHomeSection ? '/' : '/SomosPMCustom';

        if (location.pathname !== targetPath) {
            navigate(targetPath);
            setPendingScroll(key);
        } else {
            handleScrollTo(key);
        }
    };

    const handleScrollTo = (key) => {
        if (key === 'home' && homeRef.current) {
            homeRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (key === 'servicios' && serviciosRef.current) {
            serviciosRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (key === 'corfo' && corfoRef.current) {
            corfoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // -- Estados para el modal de "Otros Servicios" --
    const [open, setOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const handleClickOpen = (service) => {
        setSelectedService(service);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedService(null);
    };

    // -- Listado de servicios a mostrar (para el modal "Otros Servicios") --
    const servicios = [
        {
            nombre: "Desarrollo de Dispositivos",
            imagen: Desarrollodispositivos,
            descripcion: "Ofrecemos servicios especializados en el desarrollo de dispositivos embebidos, tales como sistemas de comunicación, sensorización y control, garantizando instrumentos eficientes, precisos y fiables, que se adaptan a las brechas tecnológicas del mercado y a las necesidades de cada cliente."
        },
        {
            nombre: "Dashboard de Control Web",
            imagen: Dashboarddecontrolweb,
            descripcion: "Los dashboard diseñados por PMCustom son paneles de control personalizados que te ofrecen información procesada y presentada en diversos formatos de visualización. Esto incluye ratios, gráficos, mapas, semáforos, notificaciones y widgets, entre muchas otras opciones. Con esta información a tu alcance, podrás contar con mayor control y una toma de decisiones más ágil para tu negocio."
        },
        {
            nombre: "Integraciones Web",
            imagen: Integracionesweb,
            descripcion: "Diseñamos y desarrollamos aplicaciones web personalizadas que se integran a los procesos de tu negocio, garantizando seguridad, escalabilidad y una experiencia amigable para el usuario, al facilitar el acceso a la información,  creando nuevos servicios y mejorando la interacción y la comunicación de los clientes."
        },
        {
            nombre: "Servidores",
            imagen: Servidores,
            descripcion: "Ofrecemos soluciones de servidor a la medida de tus necesidades, diseñadas para alojar tus aplicaciones, almacenar tus datos y protegerlos con copias de seguridad, permitiendo tener acceso a la información desde cualquier dispositivo, de manera oportuna e inmediata durante las 24 horas y en cualquier parte del mundo."
        },
    ];

  


    return (
        <>
            {/* Navbar con la función handleScrollTo */}
            <NavbarL onNavigate={handleScrollTo} />
            <Box sx={{ height: 90}} />

            {/* Botón de WhatsApp flotante */}
            <FloatingWhatsAppButton />

            {/* Sección HOME */}
            <Box
                ref={homeRef}
                style={containerStyle}
                sx={{
                    width: '100%',
                    height: 740,
                }}
            >
                <br />
                <Container maxWidth="xl" sx={{ marginBottom: '10px', mt: 30 }}>
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="left" alignItems="center">
                            <Grid item justifyContent="left" alignItems="left" container sx={12} md={9}>
                                <Typography
                                    variant="h3"
                                    align="left"
                                    style={{ color: "white", fontWeight: "bold", letterSpacing: "-0.04em" }}
                                    gutterBottom
                                >
                                    Somos tu Partner Tecnológico
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '22px',
                                        textAlign: 'justify',
                                        color: "white"
                                    }}
                                >
                                    PMCustom es una empresa de tecnologia especializada en el diseño y
                                    desarrollo de sistemas embebidos innovadores. Nuestras soluciones
                                    permiten adquirir, centralizar y mostrar información procesada,
                                    facilitando la toma de decisiones informadas.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Container>
                <Grid ref={serviciosRef}></Grid>
            </Box>

            {/* Sección NUESTROS SERVICIOS */}
            
            <Container
                maxWidth="xl"
                sx={{ marginTop: 5, marginBottom: '10px' }}
            >
                <Typography
                    variant="h3"
                    align="center"
                    style={{ fontWeight: "bold", letterSpacing: "-0.04em", color: "#010F62" }}
                    gutterBottom
                >
                    Nuestros Servicios
                </Typography>

                <Typography
                    sx={{
                        m: 5,
                        fontSize: '17px',
                        textAlign: 'justify',
                        color: "#010F62"
                    }}
                >
                    Los servicios entregados por PMCustom permiten integrar,
                    diseñar, desarrollar y/o desplegar dispositivos tecnológicos
                    automáticos que se complementan con interfaces web, facilitando
                    así la obtención de información procesada y precisa para la gestión
                    eficiente de las operaciones de tu negocio en tiempo real. Además,
                    impulsamos la innovación empresarial a través del diseño personalizado
                    de paquetes tecnológicos. También brindamos asesorías para evaluar la
                    admisibilidad de proyectos tecnológicos a recursos financieros CORFO,
                    ley I+D y fondos concursables.
                </Typography>

                <Grid container xs={12} md={12} justifyContent="center" alignItems="center" sx={{ marginTop: 5 }}>
                    <Button
                        variant="text"
                        onClick={() => handleMenuClick('Contactanos')}
                        sx={{
                            bgcolor: '#010F62',
                            color: 'white',
                            padding: '10px',
                            borderRadius: '30px',
                            width: '260px',
                            fontSize: '17px',
                            marginTop: 5,
                            textAlign: 'justify',
                            fontWeight: 'bold',
                            letterSpacing: "-0.04em"
                        }}
                    >
                        Cuentanos tu proyecto
                    </Button>
                </Grid>
            </Container>

            {/* Sección con Proyectos IoT, I+D, etc. */}
            <Box
                style={containerStylee}
                sx={{
                    mt: 10,
                    width: '100%',
                    height: '100%',
                }}
            >
                <Container
                    maxWidth="xl"
                >
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="center" alignItems="center" spacing={3} sx={{ marginTop: 5, mb: 10, ml: 4, mr: 4 }}>
                            <Grid item justifyContent="center" alignItems="center" container sx={6} md={6}>
                                <CardMedia
                                    component="img"
                                    image={ProyectosIot_edited}
                                    sx={{
                                        maxWidth: "80%",
                                        maxHeight: "80%",
                                        margin: "0 auto",
                                        objectFit: "contain"
                                    }}
                                />
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container sx={6} md={6}>
                                <Typography
                                    variant="h3"
                                    align="center"
                                    style={{ color: "white", fontWeight: "bold", letterSpacing: "-0.04em" }}
                                    gutterBottom
                                >
                                    Proyectos IoT
                                </Typography>
                                <Grid item justifyContent="center" alignItems="center" container sx={12} md={12} />
                                <Typography
                                    variant="h5"
                                    align="center"
                                    style={{ color: "white", letterSpacing: "-0.04em", marginBottom: 50 }}
                                    gutterBottom
                                >
                                    (Internet of Things)
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '17px',
                                        textAlign: 'justify',
                                        color: "white"
                                    }}
                                >
                                    Innovamos con soluciones de Internet de las Cosas (IoT) personalizadas,
                                    diseñadas para optimizar procesos y mejorar la conectividad de tus
                                    dispositivos, desde la integración de sensores hasta la automatización
                                    completa. Transformamos tu negocio a través de tecnología avanzada.
                                </Typography>
                            </Grid>

                            <Grid item justifyContent="center" alignItems="center" container sx={6} md={6}>
                                <Typography
                                    item
                                    container
                                    variant="h3"
                                    align="center"
                                    style={{ color: "white", fontWeight: "bold", letterSpacing: "-0.04em" }}
                                    gutterBottom
                                >
                                    I + D
                                </Typography>
                                <Grid item justifyContent="center" alignItems="center" container sx={12} md={12} />
                                <Typography
                                    item
                                    container
                                    variant="h5"
                                    align="center"
                                    style={{ color: "white", letterSpacing: "-0.04em", marginBottom: 50 }}
                                    gutterBottom
                                >
                                    Investigación y Desarollo
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '17px',
                                        textAlign: 'justify',
                                        color: "white"
                                    }}
                                >
                                    Mediante métodos científicos, llevamos a cabo procesos de investigación
                                    y desarrollo que generan nuevos conocimientos, los que nos permiten
                                    crear avances tecnológicos innovadores, relevantes, viables y aplicables
                                    a procesos y productos, los que en ocasiones tienen el potencial de
                                    ser patentados y protegidos por la propiedad intelectual.
                                </Typography>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container sx={6} md={6}>
                                <CardMedia
                                    component="img"
                                    sx={{
                                        maxWidth: "80%",
                                        maxHeight: "80%",
                                        margin: "0 auto",
                                        objectFit: "contain"
                                    }}
                                    image={ProyectosIot_edited}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Sección para más contenido CORFO (u otro) */}
            <Box
                ref={corfoRef}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Container maxWidth="xl" sx={{ marginTop: '-10px', marginBottom: '10px' }}>
                    <Grid
                        container
                        xs={12}
                        md={12}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ marginTop: 1, marginBottom: 3 }}
                    >
                        <Button
                            variant="text"
                            onClick={() => handleMenuClick('Contactanos')}
                            sx={{
                                bgcolor: '#010F62',
                                color: 'white',
                                padding: '10px',
                                borderRadius: '30px',
                                width: '260px',
                                fontSize: '17px',
                                marginTop: 5,
                                textAlign: 'justify',
                                fontWeight: 'bold',
                                letterSpacing: "-0.04em"
                            }}
                        >
                            Te asesoramos
                        </Button>
                    </Grid>
                </Container>
            </Box>

            {/* Otra sección de Fondo (IoT, Corfo, etc.) */}
            <Box
                style={containerStylee}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Container maxWidth="xl">
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="center" alignItems="center" spacing={3} sx={{ marginTop: 5, mb: 10, ml: 4, mr: 4 }}>
                            <Grid item justifyContent="center" alignItems="center" container sx={12} md={12}>
                                <CardMedia
                                    component="img"
                                    image={Imagen_Corfo}
                                    sx={{
                                        maxWidth: "450px",
                                        maxHeight: "450px",
                                        margin: "0 auto",
                                        objectFit: "contain"
                                    }}
                                />
                            </Grid>
                            <Grid item justifyContent="left" alignItems="left" container sx={5} md={5}>
                                <Typography sx={{ fontSize: '17px', textAlign: 'justify', color: "white" }}>
                                    <strong>PMCustom</strong> te ayuda a obtener recursos en Corfo, a través de sus dos vías de financiamiento:
                                    <strong> Ley I+D y Fondos Concursables</strong>. Nuestra gestión es un servicio integral que se divide en dos etapas:
                                </Typography>

                                <Typography alignItems="left" sx={{ fontSize: '17px', textAlign: 'justify', color: "white", marginTop: 2 }}>
                                    <strong>1. Asesoramiento:</strong>
                                </Typography>
                                <Typography sx={{ fontSize: '17px', color: "white", ml: 5 }}>
                                    - Formulación y postulación a fondos concursantes.
                                </Typography>
                                <Typography sx={{ fontSize: '17px', color: "white", ml: 5 }}>
                                    - Asesoramiento para acceder a la Ley I+D.
                                </Typography>

                                <Typography sx={{ fontSize: '17px', textAlign: 'justify', color: "white", marginTop: 2 }}>
                                    <strong>2. Desarrollo e Implementación:</strong>
                                </Typography>
                                <Typography sx={{ fontSize: '17px', color: "white", ml: 5 }}>
                                    - Ejecución de las etapas del proyecto, asegurando su éxito.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth="xl" sx={{ marginTop: 5, marginBottom: '10px' }}>
                <Typography
                    variant="h3"
                    align="center"
                    style={{ fontWeight: "bold", letterSpacing: "-0.04em", color: "#010F62" }}
                    gutterBottom
                >
                    Planifica tu Futuro con PMCustom
                </Typography>

                <Typography
                    sx={{
                        m: 5,
                        mb: -0.5,
                        fontSize: '17px',
                        textAlign: 'justify',
                        color: "#010F62"
                    }}
                >
                    Instalamos sensores en terreno que recopilan datos sobre tus procesos productivos.
                    Estos datos se envían a un módulo central en campo para un preprocesamiento inicial.
                    Posteriormente, la información viaja a un servidor en la nube donde se organiza,
                    tabula y filtra, entregando información cuantificada en una interfaz personalizada.
                    Esta información te permite tomar decisiones estratégicas y acciones sobre tu
                    proceso productivo, controlando y planificando tu negocio de manera eficiente.
                </Typography>
                <CardMedia
                    component="img"
                    image={ciclopmcustom}
                    sx={{
                        width: { xs: "100%", md: "90%" },
                        height: "auto",
                        margin: "0 auto",
                        objectFit: "contain"
                    }}
                />
                <br />
            </Container>

            <Box
                style={containerStylee}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Container maxWidth="xl">
                    <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{ marginTop: 5, mb: 10 }}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h3"
                                align="center"
                                sx={{ fontWeight: "bold", letterSpacing: "-0.04em", color: "white" }}
                                gutterBottom
                            >
                                Otros Servicios
                            </Typography>
                        </Grid>

                        <Grid container justifyContent="center" alignItems="center" spacing={2} xs={12}>
                            {servicios.map((service, index) => (
                                <Grid item xs={2.5} key={index}>
                                    <CardMedia
                                        component="img"
                                        image={service.imagen}
                                        sx={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            margin: "0 auto",
                                            objectFit: "contain",
                                            borderRadius: '25px',
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleClickOpen(service)}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ marginTop: 5, marginBottom: 3 }}
                        >
                            <Button
                                variant="text"
                                onClick={() => handleMenuClick('Contactanos')}
                                sx={{
                                    bgcolor: 'white',
                                    color: '#010F62',
                                    padding: '10px',
                                    borderRadius: '30px',
                                    width: '300px',
                                    fontSize: '16px',
                                    marginTop: 5,
                                    textAlign: 'justify',
                                    fontWeight: 'bold',
                                    letterSpacing: "-0.04em",
                                }}
                            >
                                Contacta a un Profesional
                            </Button>
                        </Grid>
                    </Grid>

                    <Dialog open={open} onClose={handleClose} maxWidth="md">
                        {selectedService && (
                            <DialogContent
                                sx={{
                                    textAlign: "center",
                                    bgcolor: "#121212",
                                    color: "white",
                                    padding: "20px",
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={selectedService.imagen}
                                    sx={{ maxWidth: "65%", borderRadius: "10px", textAlign: "center", margin: "0 auto" }}
                                />
                                <Typography variant="h5" sx={{ fontWeight: "bold", marginTop: 2 }}>
                                    {selectedService.nombre}
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 2 }}>
                                    {selectedService.descripcion}
                                </Typography>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    sx={{ marginTop: 3, bgcolor: "white", color: "#121212", fontWeight: "bold" }}
                                >
                                    Cerrar
                                </Button>
                            </DialogContent>
                        )}
                    </Dialog>
                </Container>
            </Box>

            {/* Footer */}
            <TerminoPag />
        </>
    );
};

export default Home;
