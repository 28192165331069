import React, { useState, useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
    Grid,
    Box,
    Typography,
    Button,
    Container,
    CardMedia,
    Card,
    CardContent,
    TextField,
    Snackbar,
    Alert,

} from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import LogoPlacaPmCustom from "./../Imagenes/Logo_Placa_PmCustom.jpg";

import fiabilidad_2 from "./../Imagenes/fiabilidad_2.jpg";
import usabilidad_2 from "./../Imagenes/usabilidad_2.jpg";
import seguridad_2 from "./../Imagenes/seguridad_2.png";

import mathias from "./../Imagenes/mathias.png";
import pancho from "./../Imagenes/pancho.png";
import vivian from "./../Imagenes/Vivian.png";
import Michael from "./../Imagenes/Michael.png";

import contacto from "./../Imagenes/Contacto.jpg";


import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ControlDeRiego from "./../Imagenes/ControlDeRiego.jpg";
import Nodo from "./../Imagenes/Nodo.jpg";
import DataLogger from "./../Imagenes/DataLogger.jpg";

import { useNavigate, useLocation } from 'react-router-dom';

const productos = [
    {
        titulo: "Tablero Control de Riego PMCustom",
        image: ControlDeRiego,
        descripcion:
            "Este dispositivo controla el centro de irrigación, activando bombas de riego y fertilización, también monitorea presión y flujo de agua asegurando el correcto funcionamiento del sistema.\n\n" +
            "· Programación web.\n" +
            "· Comunicación celular con la nube.\n" +
            "· 4 entradas para control de caudalímetro.\n" +
            "· 4 relés para encendido de generador y bombas de riego.\n" +
            "· 4 salidas latch para válvulas.\n" +
            "· 4 entradas analógicas para sensores de presión.\n" +
            "· LEDs de estados de salidas y entradas en placa.\n" +
            "· Entradas de sensores con interfaz SDI-12 para lectura de CE.",
    },
    {
        titulo: "Nodo PMCustom",
        image: Nodo,
        descripcion:
            "El nodo PMCustom es un sistema que controla hasta dos válvulas de riego, permitiendo distribuir el flujo de agua y fertilizantes por sectores.\n\n" +
            "· Alimentación solar.\n" +
            "· Red local con tecnología LoRa para comunicación con el control.\n" +
            "· Entrada para sensor de presión.\n" +
            "· 2 salidas Latch para válvulas.",
    },
    {
        titulo: "Data Logger DSL1 PMCustom",
        image: DataLogger,
        descripcion:
            "El Data Logger DSL1 PMCustom es un sistema que permite recopilar información y visualizarla en una plataforma o dashboard. Este dispositivo cuenta con un máximo de 3 periféricos personalizables, y cada uno de ellos permite integrar sensores que miden una gran variedad de magnitudes físicas como la temperatura, la presión, la longitud, la fuerza, la humedad, la velocidad, el nivel, la posición, el color y el sonido, entre muchas otras variables, dependiendo de los requerimientos del usuario y la información que se quiera recopilar.\n\n" +
            "· 2 entradas digitales.\n" +
            "· Entrada para sensores con interfaz SDI-12.\n" +
            "· Alimentación solar.\n" +
            "· Comunicación celular para conexión con la nube.\n" +
            "· Envío de datos con frecuencia configurable.",
    },
];

const SomosPMCustom = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [pendingScroll, setPendingScroll] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const [giroEmpresa, setGiroEmpresa] = useState('');
    const [rutEmpresa, setRutEmpresa] = useState('');
    const [nombreContacto, setNombreContacto] = useState('');
    const [telefonoContacto, setTelefonoContacto] = useState('');
    const [comentarios, setComentarios] = useState('');


    const mostrarAlertaFaltaInformacion = () => {
        setMostrarAlerta(true);
    };

    const mostrarAlertaGuardadoExito = () => {
        setMostrarAlertaExito(true);
    };


    const handleMenuClick = (key) => {
        const isHomeSection = ['home', 'servicios', 'corfo'].includes(key);
        const targetPath = isHomeSection ? '/' : '/SomosPMCustom';

        if (location.pathname !== targetPath) {
            navigate(targetPath);
            setPendingScroll(key);
        } else {
            handleScrollTo(key);
        }
    };

    const handleEnviar = async () => {
        if (
            nombreEmpresa &&
            giroEmpresa &&
            rutEmpresa &&
            nombreContacto &&
            telefonoContacto &&
            comentarios
        ) {
            // Enviar datos
            mostrarAlertaGuardadoExito();
        }
        else {
            mostrarAlertaFaltaInformacion();
        }
    }

    const handleScrollTo = (key) => {
        if (key === "SomosPMCustom" && somosRef.current) {
            somosRef.current.scrollIntoView({ behavior: "smooth" });
        } else if (key === "Contactanos" && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const containerStyle = {
        background: `linear-gradient(rgba(0, 0, 60, 0.7), rgba(0, 0, 60, 0.7)), url('${LogoPlacaPmCustom}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        minHeight: '30vh',
    };

    const containerStylee = {
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${LogoPlacaPmCustom}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '20vh',
    };

    const pilares = [
        {
            titulo: "Seguridad",
            image: seguridad_2,
            descripcion: "Garantizamos el máximo nivel de seguridad para todos los productos de PMCustom, trabajando con herramientas y softwares con protocolos industriales, realizando pruebas de seguridad sistemáticas e integrando actualizaciones de firmware periódicas, eliminan así cualquier riesgo de vulnerabilidad en los flujos y ambientes de la información.",
        },
        {
            titulo: "Robustez",
            image: fiabilidad_2,
            descripcion: "Contamos con una larga experiencia en la fabricación de dispositivos tecnológicos para diferentes industrias, capaces de soportar los entornos más rigurosos y de ofrecer continuidad en la conexión con múltiples escenarios de respaldo, ofreciendo una robustez en la disponibilidad de los datos, permitiendo así un control y una gestión eficiente de tu negocio en tiempo real.",
        },
        {
            titulo: "Usabilidad",
            image: usabilidad_2,
            descripcion: "Nuestros dispositivos industriales están diseñados para aplicaciones profesionales, combinando alta funcionalidad con facilidad de uso. No requieren formación especializada, permitiendo un ahorro significativo de tiempo en la fase de implementación.",
        },
    ];

    const Valores = [
        {
            titulo: "Misión",
            image: seguridad_2,
            descripcion: "Nuestra visión es ser la organización líder en la creación, implementación y mantenimiento de proyectos que fomenten la innovación tecnológica sustentable. Nos comprometemos a transformar la industria, haciéndola más precisa y eficiente, alcanzando niveles óptimos de productividad. Al mismo tiempo, nos aseguramos de que nuestras acciones minimicen el impacto en el medio ambiente, promoviendo un futuro más sostenible para todos.",
        },
        {
            titulo: "Visión",
            image: fiabilidad_2,
            descripcion: "Somos una organización de estructura horizontal, capaz de empatizar con nuestros clientes al integrarnos a sus operaciones y comprender las problemáticas particulares de su industria, capturanddo el valor del negocio. Nuestro enfoque se centra en desarrollar soluciones tecnológicas avanzadas que estén alineadas con los principios de sustentabilidad y respeto por el medio ambiente.",
        },
        {
            titulo: "Propuesta de Valor",
            image: usabilidad_2,
            descripcion: "En PMCustom, entendemos que conocer las problemáticas de nuestros clientes y su entorno es esencial para ofrecer soluciones adaptadas a la industria. Por ello, aplicamos metodologías flexibles y contamos con un profundo conocimiento del mercado tecnológico. Acompañamos a nuestros clientes en cada etapa del ciclo del proyecto, desde la identificación de requerimientos hasta el diseño de soluciones, la evaluación de factibilidad técnica, el prototipado y el desarrollo del producto tecnológico.",
        },
    ];

    const equipo = [
        {
            imagen: pancho,
        },
        {
            imagen: Michael,
        },
        {
            imagen: mathias,
        },
        {
            imagen: vivian,
        },
    ];

    const somosRef = useRef(null);
    const contactRef = useRef(null);



    return (
        <>
            <NavbarL onNavigate={handleScrollTo} />
            <Box sx={{ height: 90 }} />
            <FloatingWhatsAppButton />
            <Box
                ref={somosRef}
                style={containerStyle}
                sx={{
                    width: '100%',
                    height: 740,
                }}
            >
                <br />
                <Container maxWidth="xl" sx={{ marginBottom: '10px', mt: 30 }}>
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="left" alignItems="center">
                            <Grid item justifyContent="left" alignItems="left" container sx={12} md={9}>
                                <Typography
                                    variant="h3"
                                    align="left"
                                    style={{ color: "white", fontWeight: "bold", letterSpacing: "-0.04em" }}
                                    gutterBottom
                                >
                                    Somos tu Partner Tecnológico
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '22px',
                                        textAlign: 'justify',
                                        color: "white"
                                    }}
                                >
                                    PMCustom es una empresa de tecnologia especializada en el diseño y
                                    desarrollo de sistemas embebidos innovadores. Nuestras soluciones
                                    permiten adquirir, centralizar y mostrar información procesada,
                                    facilitando la toma de decisiones informadas.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container maxWidth="xl" sx={{ marginTop: 5, marginBottom: '10px' }}>
                <Typography variant="h3" align="center" sx={{ fontWeight: "bold", color: "#010F62", mb: 3 }}>
                    ¿Por qué PMCustom?
                </Typography>
                <Typography align="center" sx={{ mb: 5, fontSize: "18px", color: "#322273" }}>
                    Nuestro compromiso se basa en tres pilares fundamentales:
                </Typography>

                <Grid container spacing={3} justifyContent="center">
                    {pilares.map((pilar, index) => (
                        <Grid item xs={12} sm={4} key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <CardMedia
                                component="img"
                                image={pilar.image}
                                sx={{
                                    width: "200px", // Tamaño uniforme
                                    height: "200px",
                                    borderRadius: "100%", // Mantiene el formato circular
                                    objectFit: "cover", // Recorta la imagen para ajustarla al tamaño sin distorsión
                                }}
                            />
                            <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold", color: "#010F62", textAlign: "center" }}>
                                {pilar.titulo}
                            </Typography>
                            <Typography sx={{ mt: 1, mx: 3, fontSize: "17px", textAlign: "justify", color: "#322273" }}>
                                {pilar.descripcion}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Grid container xs={12} md={12} justifyContent="center" alignItems="center" sx={{ marginTop: 5 }}>
                <Button
                    variant="text"
                    onClick={() => handleMenuClick('Contactanos')}
                    sx={{
                        bgcolor: '#010F62',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '30px',
                        width: '260px',
                        fontSize: '17px',
                        marginTop: 5,
                        textAlign: 'justify',
                        fontWeight: 'bold',
                        letterSpacing: "-0.04em"

                    }}
                >
                    Cuentanos tu proyecto
                </Button>
            </Grid>

            <Box style={containerStylee}
                sx={{
                    mt: 10,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Container maxWidth="xl">
                    <Grid container xs={12} md={12}>

                        <Grid item container justifyContent="center" alignItems="center" spacing={3} sx={{ marginTop: 5, mb: 10, ml: 4, mr: 4 }}>
                            <Grid item justifyContent="center" alignItems="center" container sx={12} md={12}>
                                <Typography variant="h3" align="center" style={{ color: "white", fontWeight: "bold", letterSpacing: "-0.04em" }} gutterBottom>
                                    Equipo PMCustom
                                </Typography>
                            </Grid>
                            {equipo.map((service, index) => (
                                <Grid item xs={3} key={index}>
                                    <CardMedia
                                        component="img"
                                        image={service.imagen}
                                        sx={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            margin: "0 auto",
                                            objectFit: "contain",
                                            borderRadius: '25px',
                                            cursor: "pointer",
                                        }}
                                    />
                                </Grid>
                            ))}

                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth="xl" sx={{ marginTop: 5, marginBottom: '10px' }}>
                <Typography variant="h3" align="center" sx={{ fontWeight: "bold", color: "#010F62", mb: 3 }}>
                    Valores PMCustom
                </Typography>

                <Grid container spacing={3} justifyContent="center">
                    {Valores.map((pilar, index) => (
                        <Grid item xs={12} sm={4} key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <CardMedia
                                component="img"
                                image={pilar.image}
                                sx={{
                                    width: "200px", // Tamaño uniforme
                                    height: "200px",
                                    borderRadius: "100%", // Mantiene el formato circular
                                    objectFit: "cover", // Recorta la imagen para ajustarla al tamaño sin distorsión
                                }}
                            />
                            <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold", color: "#010F62", textAlign: "center" }}>
                                {pilar.titulo}
                            </Typography>
                            <Typography sx={{ mt: 1, mx: 3, fontSize: "17px", textAlign: "justify", color: "#322273" }}>
                                {pilar.descripcion}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Grid container xs={12} md={12} justifyContent="center" alignItems="center" sx={{ marginTop: 5 }}>
            </Grid>
            <Box style={containerStylee}
                sx={{
                    width: '100%',
                    height: "100%",

                }}
            >
                <br />
                <Container maxWidth="xl" >
                    <Grid container xs={12} md={12}>
                        <Grid item container justifyContent="center" alignItems="center">
                            <Grid item justifyContent="center" alignItems="center" container sx={12} md={9}>
                                <Typography variant="h3" align="center" style={{ color: "white", fontWeight: "bold", letterSpacing: "-0.04em" }} gutterBottom>
                                    Somos tu Partner Tecnológico
                                </Typography>
                                <Grid item justifyContent="center" alignItems="center" container sx={12} md={12} />

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    startIcon={<ContactSupportIcon />}
                                    sx={{ mr: 2 }}
                                    onClick={() => handleMenuClick('Contactanos')}
                                >
                                    Cuentanos tu proyecto
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container maxWidth="xl" sx={{ marginTop: 5, marginBottom: '10px' }}>
                <Typography variant="h3" align="center" sx={{ fontWeight: "bold", color: "#010F62", mb: 3 }}>
                    ¿Por qué PMCustom?
                </Typography>
                <Typography align="center" sx={{ mb: 5, fontSize: "18px", color: "#322273" }}>
                    Nuestro compromiso se basa en tres pilares fundamentales:
                </Typography>

                <Grid container spacing={3} justifyContent="center">
                    {productos.map((produc, index) => (
                        <Grid item xs={12} sm={4} key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <CardMedia
                                component="img"
                                image={produc.image}
                                sx={{
                                    width: "300px", // Tamaño uniforme
                                    height: "300px",
                                    borderRadius: "15px", // Mantiene el formato circular
                                    objectFit: "cover", // Recorta la imagen para ajustarla al tamaño sin distorsión
                                }}
                            />
                            <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold", color: "#010F62", textAlign: "center" }}>
                                {produc.titulo}
                            </Typography>
                            <Typography sx={{ mt: 1, mx: 3, fontSize: "17px", textAlign: "justify", color: "#322273" }}>
                                {produc.descripcion}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Box sx={{ height: 90 }} ref={contactRef} />
            <Grid container xs={12} md={12} justifyContent="center" alignItems="center" sx={{ marginTop: 5 }} />

            
            <FloatingWhatsAppButton />
            <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={3}>
                            <Typography
                                color="#010F62"
                                fontWeight="bold"
                                variant={isMobile ? 'h4' : 'h3'}
                            >
                                CONTACTANOS
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={12}>
                            <Card
                                sx={{
                                    width: "100%",
                                    height: 660,
                                    borderRadius: '20px',
                                }}>
                                <Grid container xs={12} md={12}>
                                    <Grid item container justifyContent="center" alignItems="center">
                                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={6}>
                                            <Card sx={{
                                                width: "100%",
                                                height: "100%",
                                                p: 2.5,
                                            }}>
                                                <Typography variant="h5" align="center" style={{ fontWeight: "bold", letterSpacing: "-0.07em", color: "#010F62" }} gutterBottom>
                                                    INGRESA TUS DATOS
                                                </Typography>
                                                <CardContent>
                                                    <Grid container xs={12} md={12} >
                                                        <Grid item container justifyContent="center" alignItems="center" spacing={2} >
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Nombre Empresa"
                                                                    placeholder="PmCustom"
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={nombreEmpresa}
                                                                    onChange={(e) => setNombreEmpresa(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Giro Empresa"
                                                                    placeholder="Tecnologia"
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={giroEmpresa}
                                                                    onChange={(e) => setGiroEmpresa(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Rut Empresa"
                                                                    placeholder="76.123.456-7"
                                                                    type="float"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={rutEmpresa}
                                                                    onChange={(e) => setRutEmpresa(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Nombre del contacto"
                                                                    placeholder="Nombre Apellido"
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={nombreContacto}
                                                                    onChange={(e) => setNombreContacto(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Teléfono del contacto"
                                                                    placeholder="+569 12345678"
                                                                    type="number"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={telefonoContacto}
                                                                    onChange={(e) => setTelefonoContacto(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <TextField
                                                                    id={`outlined-required-`}
                                                                    label="Comentarios"
                                                                    placeholder="Necesitamos información sobre..."
                                                                    type="text"
                                                                    sx={{ width: "100%" }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    multiline
                                                                    rows={4}
                                                                    value={comentarios}
                                                                    onChange={(e) => setComentarios(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                                <Button
                                                                    size="medium"
                                                                    color="primary"
                                                                    sx={{
                                                                        width: '100%',
                                                                        bgcolor: '#010F62',
                                                                        color: 'white',
                                                                        padding: '10px',
                                                                        borderRadius: '20px',
                                                                    }}
                                                                    onClick={handleEnviar}
                                                                >
                                                                    Enviar
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" container xs={0} md={6}>
                                            <CardMedia component="img" height="700px" image={contacto} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <br />
            <br />
            <Snackbar
                open={mostrarAlerta}
                autoHideDuration={3000}
                onClose={() => setMostrarAlerta(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    Por favor, complete todos los campos obligatorios.
                </Alert>
            </Snackbar>
            <Snackbar
                open={mostrarAlertaExito}
                autoHideDuration={3000}
                onClose={() => setMostrarAlertaExito(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    ¡Enviado con éxito!
                </Alert>
            </Snackbar>



            <TerminoPag />
        </>
    )
}

export default SomosPMCustom
